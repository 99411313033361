<template>
  <div class="makeUkey">
    <div class="top">
      <div class="icon">
        <img v-if="[5,8 ].includes(info.transactionEntity.status)" src="@/assets/icons/service/await-icon.png" alt="">
        <img v-else src="@/assets/icons/service/check-success-icon.png" alt="">
      </div>
      <div v-if="[5,8 ].includes(info.transactionEntity.status)" class="hint">付款成功，您的证书正在制作</div>
      <div v-if="[10 ].includes(info.transactionEntity.status)" class="hint">证书制作完成，介质（Ukey）已寄出，注意查收，如有问题请联系400-030-2013</div>
      <div v-if="[9].includes(info.transactionEntity.status)" class="hint">证书制作完成，请前往山东豸信认证服务有限公司领取</div>
    </div>
    <div v-if="info.applyFormEntity.shipping" class="title">收件信息<span v-if="[5,8 ].includes(info.transactionEntity.status)">（等待证书制作完成后立即给您邮寄）</span></div>
    <div v-else class="title">领取地址<span v-if="[5,8 ].includes(info.transactionEntity.status)">（等待证书制作完成后，请前往豸信CA领取）</span> </div>
    <div class="info">
      <template v-if="info.applyFormEntity.shipping">
        <div><span class="lb">收件人</span><span>{{info.applyFormEntity.orgInfoEntity.agentName}}</span></div>
        <div><span class="lb">手机号</span><span>{{$util.telSecret(info.applyFormEntity.orgInfoEntity.agentMobile)}}</span></div>
        <div><span class="lb">收件地址</span><span>{{info.applyFormEntity.mailingAddress}}</span></div>
        <template v-if="[10].includes(info.transactionEntity.status)">
          <div><span class="lb">快递公司</span><span>{{info.ukeyLogisticsEntity.expressName}}</span></div>
          <div><span class="lb">运单号</span><span>{{info.ukeyLogisticsEntity.expressNo}}</span>
            <el-button @click="queryExpressNo" type="text">查询</el-button>
          </div>
        </template>
      </template>
      <template v-else>
        <div><span class="lb">地址</span><span>山东省济南市历城区唐冶绿地城市大脑数字产业小镇AD23号楼101（商墅）</span>
        </div>
        <div><span class="lb">咨询电话</span><span>400-030-2013</span></div>
      </template>
    </div>
    <div class="button">
      <el-button type="primary" @click="query">查看我的业务单</el-button>
    </div>
    <MailNews ref="mailNews" />
  </div>
</template>

<script>
import MailNews from '@/components/mailNews'
export default {
  props: ['info'],
  components: { MailNews },
  methods: {
    query () {
      this.$router.push({ name: 'serviceCenter', query: { type: 1 } })
    },
    queryExpressNo () {
      let expressNo = this.info.ukeyLogisticsEntity.expressNo
      this.$refs.mailNews.getmailNewData(expressNo)
    }
  }
}
</script>

<style lang="scss" scoped>
.makeUkey {
  color: #303b50;
  padding: 44px 14.3%;
  .top {
    padding: 56px 0;
    text-align: center;
    border-bottom: 1px #dee2e8 solid;
    margin-bottom: 56px;
    .icon {
      margin-bottom: 16px;
      img {
        width: 36px;
        height: 36px;
      }
    }
    .hint {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
    span {
      color: #ed5448;
    }
  }
  .info {
    padding: 24px 32px;
    background: #f7faff;
    border-radius: 2px;
    line-height: 19px;
    & > div {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      &:last-child {
        margin: 0;
      }
      span {
        &.lb {
          width: 106px;
          flex-shrink: 0;
        }
      }
      .el-button {
        padding: 0;
        margin-left: 12px;
        line-height: 19px;
      }
    }
  }
  .button {
    margin: 62px auto 0;
    text-align: center;
  }
}
</style>