<template>
  <div class="project-list clearfix">
    <div class="title">项目选择</div>
    <div class="project" v-for="(item) in projectList" @click="projectClick(item)" :key="item.id">
      <img src="@/assets/icons/service/filesUpload.png" class="left-icon" alt="" srcset="">
      <img src="@/assets/icons/service/project-icon.png" class="project-icon" alt="" srcset="">
      <div class="name">{{item.name}}</div>
      <svg-icon class="r-icon" icon-class="pulldown" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'selectProject',
  data () {
    return {
      projectList: []
    }
  },
  created () {
    this.getProjectList()
  },
  methods: {
    getProjectList () {
      this.$api.getProjectList().then(res => {
        this.projectList = res.data
      })
    },
    projectClick (project) {
      this.$parent.projectId = project.id
      this.$parent.stepActive = -2
      // this.$parent.agreementShow = true
      this.$parent.sealProject = project.sealProject
    }
  }
}
</script>
<style lang="scss" scoped>
.project-list {
  padding: 0 24px;
  .title {
    font-size: 16px;
    color: #303b50;
    line-height: 52px;
    border-bottom: 1px #dee2e8 solid;
    margin-bottom: 32px;
  }
  .project {
    display: flex;
    background: #f7faff;
    margin-bottom: 40px;
    align-items: center;
    border: 1px solid #dee2e8;
    padding-right: 24px;
    cursor: pointer;
    width: 602px;
    float: left;
    &:nth-child(2n + 1) {
      float: right;
    }
    &:hover {
      border-color: #1c67f3;
      .name,
      .r-icon {
        color: $primaryColor;
      }
    }
    * {
      flex-shrink: 0;
    }
    .left-icon {
      width: 24px;
      height: 72px;
    }
    .project-icon {
      width: 34px;
      height: 34px;
      margin-left: 28px;
      margin-right: 20px;
    }
    .name {
      flex-shrink: 1;
      width: 100%;
      margin-right: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #303b50;
    }
    .r-icon {
      transform: rotate(-90deg);
      color: #aeb5be;
      font-size: 12px;
    }
  }
}
</style>