<template>
  <div class="certApply">
    <Steps v-if="stepActive>=0" :steps="steps" :active="stepActive" />
    <div class="returnCrumbs" v-if="stepActive<0">
      <router-link :to="{name:'serviceHall'}">
        服务大厅
      </router-link>
      <span>></span>
      <el-button type="text" :class="{'crumbsActive':stepActive===-1}">选择项目</el-button>
      <span v-if="stepActive===-2">></span>
      <el-button type="text" :class="{'crumbsActive':stepActive===-2}" v-if="stepActive===-2">申请指南</el-button>
    </div>
    <div class="step-content">
      <template v-if="stepActive===-1">
        <SelectProject></SelectProject>
      </template>
      <Agreement v-model="agreementShow" @affirm="stepActive = 0" />
      <Guide v-if="stepActive===-2"></Guide>
      <Information v-if="stepActive===0" :projectId="projectId" :sealProject="sealProject" />
      <UploadFile v-if="stepActive===1" :info="transactionDetail" />
      <CheckData v-if="[2,3].includes(stepActive)" :info="transactionDetail" />
      <Peyment v-if="[4].includes(stepActive)" :info="transactionDetail" />
      <MakeUkey v-if="[5,8,9,10].includes(stepActive)" :info="transactionDetail" />
      <Complete v-if="[11,12,13].includes(stepActive)" :info="transactionDetail" />
    </div>
  </div>
</template> 

<script>
// 公共
import Steps from '../components/steps.vue'
import CheckData from '../components/checkData.vue'
import Peyment from '../components/peyment.vue'
import UploadFile from '../components/uploadFile.vue'
import Complete from '../components/complete.vue'
import Agreement from '../components/agreement.vue'
// 私有
import Information from './information.vue'
import Guide from './guide.vue'
import SelectProject from './selectProject.vue'
import MakeUkey from './makeUkey.vue'

export default {
  components: {
    Steps,
    // StepContent,
    Agreement,
    Information,
    SelectProject,
    UploadFile,
    CheckData,
    Peyment,
    MakeUkey,
    Complete,
    Guide
  },
  data () {
    return {
      transId: '',
      steps: [
        {
          name: '信息备案',
          icon: 'service-step-zltx',
          step: [0]
        },
        {
          name: '上传资料',
          icon: 'service-step-yjzl',
          step: [1]
        },
        {
          name: '资料审核',
          icon: 'service-step-zlsh',
          step: [2, 3]
        },
        {
          name: '在线付款',
          icon: 'service-step-zxfk',
          step: [4]
        },
        {
          name: '已发货',
          icon: 'service-step-jzjs',
          step: [5, 8, 9, 10]
        },
        {
          name: '业务完成',
          icon: 'service-step-ywc',
          step: [11, 12, 13]
        }
      ],
      agreementShow: false,
      sealProject: false,
      stepActive: -1,
      projectId: '',
      transactionDetail: ''
    }
  },
  created () {
    let transId = this.$route.query.transId
    if (transId) {
      this.transId = transId
    } else {
      this.stepActive = -1
    }
  },
  methods: {
    getTransactionDetail () {
      this.$api
        .getTransactionDetail({ transactionId: this.transId })
        .then(res => {
          this.transactionDetail = res.data
          this.stepActive = res.data.transactionEntity.status
        })
    }
  },
  watch: {
    transId (val) {
      if (val) {
        this.getTransactionDetail()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>