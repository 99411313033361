<template>
  <div>
    <div v-if="!confirmationShow" class="information-form">
      <el-form
        ref="information"
        size="small"
        :rules="rules"
        label-position="top"
        :model="information"
        label-width="80px"
      >
        <div class="item-title">项目信息</div>
        <el-form-item
          label="所属项目"
          class="label-position-left"
          prop="projectId"
        >
          {{ getProjectName(information.projectId) }}
        </el-form-item>
        <el-form-item
          label="证书类型"
          class="label-position-left"
          prop="certTemplateId"
        >
          <el-radio-group
            v-model="information.certTemplateId"
            @change="setSealUrl"
          >
            <el-radio
              v-for="item in certTemplates"
              :key="item.id"
              :label="item.id"
              >{{ item.alias }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          class="label-position-left"
          label="证书年限"
          prop="certDuration"
        >
          <el-radio-group v-model="information.certDuration">
            <el-radio :label="1095">3年</el-radio>
            <el-radio :label="730">2年</el-radio>
            <el-radio :label="365">1年</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="item-box">
          <el-form-item label="证书名称/CN">
            <el-input
              disabled
              :value="
                certTemplateActive.certType !== 2
                  ? information.personForm.name
                  : information.orgForm.organization
              "
            ></el-input>
          </el-form-item>
        </div>
        <div class="item-title">机构信息</div>
        <div class="item-box">
          <el-form-item label="机构名称/O" prop="orgForm.organization">
            <el-input
              v-model="information.orgForm.organization"
              placeholder="请输入机构名称"
              maxlength="60"
              @change="createPattern"
            ></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="orgForm.orgIdentNo">
            <el-input
              v-model="information.orgForm.orgIdentNo"
              placeholder="请输入统一社会信用代码"
              maxlength="18"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="部门/OU(若无部门请填写机构名称)"
            prop="orgForm.organizationalUnit"
          >
            <el-input
              v-model="information.orgForm.organizationalUnit"
              placeholder="请输入部门名称"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业注册省市" prop="orgForm.stateOrProvince">
            <el-cascader
              v-model="provinceAndCity"
              @change="provinceAndCityCg"
              :props="{ value: 'label' }"
              :options="citysData"
            ></el-cascader>
          </el-form-item>
        </div>
        <div class="item-title">法定代表人信息</div>
        <div class="item-box">
          <el-form-item label="法定代表人姓名" prop="orgForm.legalPersonName">
            <el-input
              v-model.trim="information.orgForm.legalPersonName"
              placeholder="请输入法定代表人姓名"
              minlength="2"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="法定代表人证件类型"
            prop="orgForm.legalPersonIdentType"
          >
            <el-select
              v-model="information.orgForm.legalPersonIdentType"
              placeholder="请选择法定代表人证件类型"
            >
              <el-option
                v-for="(item, index) in $DDIC.personIdentType"
                :key="index"
                :label="item"
                :value="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="法定代表人证件号码"
            prop="orgForm.legalPersonIdentNo"
            v-if="information.orgForm.legalPersonIdentType === '0'"
          >
            <el-input
              v-model="information.orgForm.legalPersonIdentNo"
              placeholder="请输入法定代表人身份证号码"
              maxlength="18"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="法定代表人证件号码"
            prop="orgForm.legalIdentNo"
            v-if="information.orgForm.legalPersonIdentType === 'Z'"
          >
            <el-input
              v-model="information.orgForm.legalIdentNo"
              placeholder="请输入法定代表人身份证号码"
              maxlength="30"
            ></el-input>
          </el-form-item>
        </div>
        <template v-if="certTemplateActive.certType !== 2">
          <div class="item-title">
            {{ sealProject || isSealForm ? '企业/机构负责人信息' : '用户信息' }}
          </div>
          <div class="item-box">
            <el-form-item label="姓名/CN" prop="personForm.name">
              <el-input
                v-model.trim="information.personForm.name"
                placeholder="请输入个人姓名"
                minlength="2"
                maxlength="60"
                @change="createPattern"
              ></el-input>
            </el-form-item>
            <el-form-item label="证件号码" prop="personForm.identNo">
              <el-input
                v-model="information.personForm.identNo"
                placeholder="请输入证件号码"
                maxlength="18"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="personForm.mobile">
              <el-input
                v-model="information.personForm.mobile"
                placeholder="请输入个人手机号"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="personForm.email">
              <el-input
                v-model="information.personForm.email"
                placeholder="请输入邮箱"
              ></el-input>
            </el-form-item>
          </div>
        </template>
        <div class="item-title" v-if="sealProject || isSealForm">
          添加印章图片
        </div>
        <div v-if="sealProject || isSealForm" class="card">
          <div class="impressionType">
            <el-button
              :type="impressionType === 'local' ? 'primary' : ''"
              plain
              @click="getSealImg('local')"
              >本地上传印章</el-button
            >
            <el-button
              :type="impressionType === 'template' ? 'primary' : ''"
              plain
              @click="getSealImg('template')"
              >模版印章</el-button
            >
          </div>
          <div class="form" v-show="impressionType === 'local'">
            <div class="tutorialBox">
              <p @click="viewTutorials">
                <span class="el-icon-question"></span> 查看抠章教程
              </p>
              <p @click="getexample">
                <span class="el-icon-question"></span> 查看上传示例
              </p>
            </div>
            <el-form-item label="" prop="patternFileBase64">
              <el-input
                style="position: absolute; clip: rect(0 0 0 0)"
                v-model="information.patternFileBase64"
              >
              </el-input>
              <SealCut
                v-loading="patternFileBase64_loading"
                :imageUrl="information.patternFileBase64"
                :certType="certTemplateActive.certType"
                @save="sealCutSave"
              >
              </SealCut>
            </el-form-item>
            <el-form-item label="印迹还原度">
              <el-slider
                class="slider"
                @change="patternMatting"
                :step="0.01"
                :min="0"
                :max="2"
                v-model="patternFileData.fixPercent"
              ></el-slider>
            </el-form-item>
            <el-form-item label="印迹颜色">
              <div class="colorBox">
                <span
                  :class="[
                    'colorBlockBox',
                    { select: patternFileData.targetColor == 0 }
                  ]"
                  @click="ImgColorSelect(0)"
                >
                  原色
                </span>
                <span
                  :class="[
                    'colorBlockBox',
                    'colorBlockCustom',
                    { select: patternFileData.targetColor == -1 }
                  ]"
                  @click="setCustomColor"
                >
                  <el-color-picker
                    v-model="patternFileData.patternCustomColor"
                    :predefine="predefineColors"
                    color-format="rgb"
                    @change="changeColor"
                    @active-change="currentColor"
                  ></el-color-picker>
                  <div class="custom">
                    <span class="el-icon-plus icon"></span>
                    <span class="title">自定义</span>
                  </div>
                </span>
              </div>
            </el-form-item>
          </div>
          <el-form
            ref="form5"
            :rules="patternRules"
            size="small"
            label-position="top"
            :model="patternData"
            label-width="100px"
            class="form uniqueform"
            v-if="impressionType === 'template'"
          >
            <div class="tutorialBox">
              <h5>
                <span class="el-icon-warning"></span>
                模版印章暂不支持使用IE浏览器
              </h5>
            </div>
            <div class="patterngenerate">
              <h2>选择印章样式</h2>
              <div v-if="certTemplateActive.certType === 2">
                <dl
                  v-for="(item, index) in $DDIC.patternStyle"
                  :key="index"
                  @click="
                    selectIndex = index
                    setPatternUrl(item)
                  "
                >
                  <dt :class="{ active: index === selectIndex }">
                    <img :src="item.img" alt="" />
                  </dt>
                  <dd>{{ item.name }}</dd>
                </dl>
              </div>
              <div v-else>
                <dl
                  v-for="(item, index) in $DDIC.patternStyle1"
                  :key="index"
                  @click="
                    selectIndex = index
                    setPatternUrl(item)
                  "
                >
                  <dt :class="{ active: index === selectIndex }">
                    <img :src="item.img" alt="" />
                  </dt>
                  <dd>{{ item.name }}</dd>
                </dl>
              </div>
              <h2>设置印章内容</h2>
              <div class="templateStyle">
                <div v-if="certTemplateActive.certType === 2">
                  <el-form-item label="实际印章尺寸" prop="size">
                    <el-select
                      v-model="patternData.size"
                      placeholder="请选择实际印章尺寸"
                      @change="setPatternSize"
                    >
                      <el-option
                        v-for="(item, index) in patternStyleType"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="中刊五角星" prop="star">
                    <el-switch
                      v-model="patternData.star"
                      :active-value="true"
                      :inactive-value="false"
                      @change="createPattern"
                    ></el-switch>
                  </el-form-item>
                </div>
                <div
                  v-if="
                    certTemplateActive.certType === 2 && selectIndex !== '2'
                  "
                >
                  <el-form-item label="环绕文">
                    <el-input
                      type="text"
                      placeholder="请输入机构名称"
                      v-model.trim="information.orgForm.organization"
                      maxlength="60"
                      class="show-word-limit"
                      show-word-limit
                      disabled
                      @change="createPattern"
                    >
                    </el-input>
                    <br />
                    <el-input
                      type="text"
                      placeholder="请输入分公司名称（非必填）"
                      v-model.trim="patternData.branchName"
                      maxlength="10"
                      class="show-word-limit branchName"
                      show-word-limit
                      @change="createPattern"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="下旋文">
                    <el-input
                      type="text"
                      placeholder="请输入下旋文"
                      v-model="patternData.sn"
                      maxlength="30"
                      class="show-word-limit"
                      show-word-limit
                      @change="createPattern"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="横向文">
                    <el-input
                      type="text"
                      placeholder="请输入横向文"
                      v-model="patternData.title"
                      maxlength="30"
                      class="show-word-limit"
                      show-word-limit
                      @change="createPattern"
                    ></el-input>
                  </el-form-item>
                </div>
                <div
                  v-if="
                    certTemplateActive.certType === 2 && selectIndex === '2'
                  "
                >
                  <el-form-item label="英文名" prop="englishName">
                    <el-input
                      v-model="patternData.englishName"
                      maxlength="60"
                      @change="createPattern"
                      placeholder="请填写英文名"
                      class="show-word-limit"
                      show-word-limit
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="英文开头偏移">
                    <el-input-number
                      v-model="patternData.englishOffset"
                      :precision="1"
                      :step="1"
                      :max="190"
                      :min="0"
                      @change="createPattern"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item label="中文名">
                    <el-input
                      v-model="information.orgForm.organization"
                      @change="createPattern"
                      placeholder="请填写中文名（机构名称）"
                      disabled
                      class="show-word-limit"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="中文开头偏移">
                    <el-input-number
                      v-model="patternData.chineseOffset"
                      :precision="1"
                      :step="1"
                      :max="190"
                      :min="0"
                      @change="createPattern"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item label="编码" prop="coding">
                    <el-input
                      v-model="patternData.coding"
                      type="text"
                      maxlength="18"
                      @change="createPattern"
                      placeholder="请填写编码"
                      class="show-word-limit"
                      show-word-limit
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="编码开头偏移">
                    <el-input-number
                      v-model="patternData.codingOffset"
                      :precision="1"
                      :step="1"
                      :max="190"
                      :min="0"
                      @change="createPattern"
                    ></el-input-number>
                  </el-form-item>
                </div>
                <div
                  class="fontSettings"
                  v-if="
                    certTemplateActive.certType === 2 && selectIndex !== '2'
                  "
                >
                  <h3>环绕文（常规默认）</h3>
                  <el-form-item label="字号" prop="fontsizea">
                    <el-select
                      v-model="patternData.fontsizea"
                      placeholder="请选择实际印章字号"
                      @change="createPattern"
                    >
                      <el-option
                        v-for="item in [
                          16,
                          17,
                          18,
                          19,
                          20,
                          21,
                          22,
                          23,
                          24,
                          25,
                          26,
                          27,
                          28
                        ]"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="字间距" prop="spacinga">
                    <el-select
                      v-model="patternData.spacinga"
                      placeholder="请选择实际印章字间距"
                      @change="createPattern"
                    >
                      <el-option
                        v-for="item in [
                          -3,
                          -2,
                          -1,
                          0,
                          1,
                          2,
                          3,
                          4,
                          5,
                          6,
                          7,
                          8,
                          9,
                          10,
                          11,
                          12,
                          13,
                          14,
                          15,
                          16,
                          17,
                          18,
                          19,
                          20
                        ]"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="字高" prop="fontheighta">
                    <el-select
                      v-model="patternData.fontheighta"
                      placeholder="请选择实际印章字高"
                      @change="createPattern"
                    >
                      <el-option
                        v-for="item in [
                          100,
                          110,
                          120,
                          130,
                          140,
                          150,
                          160,
                          170,
                          180,
                          190,
                          200,
                          210,
                          220
                        ]"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="字宽" prop="fontwidtha">
                    <el-select
                      v-model="patternData.fontwidtha"
                      placeholder="请选择实际印章字宽"
                      @change="createPattern"
                    >
                      <el-option
                        v-for="item in [
                          20,
                          30,
                          40,
                          50,
                          60,
                          70,
                          80,
                          90,
                          100,
                          110,
                          120,
                          130,
                          140,
                          150,
                          160,
                          170,
                          180
                        ]"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <h3>横向文（常规默认）</h3>
                  <el-form-item label="字号" prop="fontsizeb">
                    <!-- <el-input-number v-model="patternData.fontsizeb" controls-position="right" @change="createPattern" :min="10" :max="28"></el-input-number> -->
                    <el-select
                      v-model="patternData.fontsizeb"
                      placeholder="请选择实际印章字号"
                      @change="createPattern"
                    >
                      <el-option
                        v-for="item in [
                          10,
                          11,
                          12,
                          13,
                          14,
                          15,
                          16,
                          17,
                          18,
                          19,
                          20,
                          21,
                          22,
                          23,
                          24,
                          25,
                          26,
                          27,
                          28
                        ]"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="字间距" prop="spacingb">
                    <el-select
                      v-model="patternData.spacingb"
                      placeholder="请选择实际印章字间距"
                      @change="createPattern"
                    >
                      <el-option
                        v-for="item in [
                          -3,
                          -2,
                          -1,
                          0,
                          1,
                          2,
                          3,
                          4,
                          5,
                          6,
                          7,
                          8,
                          9,
                          10,
                          11,
                          12,
                          13,
                          14,
                          15,
                          16,
                          17,
                          18,
                          19,
                          20
                        ]"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="字高" prop="fontheightb">
                    <el-select
                      v-model="patternData.fontheightb"
                      placeholder="请选择实际印章字高"
                      @change="createPattern"
                    >
                      <el-option
                        v-for="item in [
                          100,
                          110,
                          120,
                          130,
                          140,
                          150,
                          160,
                          170,
                          180,
                          190,
                          200,
                          210,
                          220
                        ]"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="字宽" prop="fontwidthb">
                    <el-select
                      v-model="patternData.fontwidthb"
                      placeholder="请选择实际印章字宽"
                      @change="createPattern"
                    >
                      <el-option
                        v-for="item in [
                          20,
                          30,
                          40,
                          50,
                          60,
                          70,
                          80,
                          90,
                          100,
                          110,
                          120,
                          130,
                          140,
                          150,
                          160,
                          170,
                          180
                        ]"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <el-form-item
                  label="防伪环"
                  prop="antiFake"
                  v-if="
                    certTemplateActive.certType === 2 && selectIndex === '0'
                  "
                >
                  <el-switch
                    v-model="patternData.antiFake"
                    :active-value="true"
                    :inactive-value="false"
                    @change="createPattern"
                  ></el-switch>
                </el-form-item>
                <el-form-item
                  label="实际印章尺寸"
                  prop="size"
                  v-if="
                    certTemplateActive.certType !== 2 && selectIndex === '0'
                  "
                >
                  <el-select
                    v-model="patternData.size"
                    placeholder="请选择实际印章尺寸"
                    @change="setPatternSize"
                  >
                    <el-option
                      v-for="(item, index) in patternStyleType"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <div v-if="certTemplateActive.certType !== 2">
                  <el-form-item label="章名" ref="flatName">
                    <el-input
                      v-model="information.personForm.name"
                      placeholder="请输入姓名"
                      maxlength="4"
                      @change="createPattern"
                      class="show-word-limit"
                      show-word-limit
                      disabled
                    ></el-input>
                  </el-form-item>
                </div>
                <el-form-item
                  label="印迹颜色"
                  v-if="certTemplateActive.certType !== 2"
                >
                  <div class="select-targetColors">
                    <div
                      v-for="(color, index) in patternColors"
                      :class="{
                        active: index + 1 === patternFileData.targetColor
                      }"
                      @click="
                        patternFileData.targetColor = index + 1
                        setPatternColor()
                      "
                      :key="index"
                    >
                      <span :style="`background: ${color};`">
                        <i
                          v-if="index + 1 == patternFileData.targetColor"
                          class="el-icon-circle-check"
                        ></i>
                      </span>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="做旧" prop="makeOldType">
                  <el-radio-group
                    v-model="patternData.makeOldType"
                    @change="createPattern"
                  >
                    <el-radio :label="0">无做旧</el-radio>
                    <el-radio :label="1">做旧一</el-radio>
                    <el-radio :label="2">做旧二</el-radio>
                    <el-radio :label="3">做旧三</el-radio>
                    <el-radio :label="4">做旧四</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="sealArea">
                <p>印章预览</p>
                <div class="sealImg">
                  <iframe
                    id="sealAreas"
                    ref="sealAreas"
                    :src="exportSrc"
                    frameborder="0"
                    width="232px"
                    height="232px"
                  ></iframe>
                </div>
                <el-form-item label="" v-if="certTemplateActive.certType === 2">
                  <div class="select-targetColors">
                    <div
                      v-for="(color, index) in patternColors"
                      :class="{
                        active: index + 1 === patternFileData.targetColor
                      }"
                      @click="
                        patternFileData.targetColor = index + 1
                        setPatternColor()
                      "
                      :key="index"
                    >
                      <span :style="`background: ${color};`">
                        <i
                          v-if="index + 1 == patternFileData.targetColor"
                          class="el-icon-circle-check"
                        ></i>
                      </span>
                    </div>
                  </div>
                </el-form-item>
              </div>
            </div>
          </el-form>
          <div class="handwrittenArea" v-if="impressionType === 'handwritten'">
            <div class="handwrittenCode">
              <div class="qrCode">
                <img :src="qrCodeImg" alt="" />
              </div>
              <div class="codeTips">
                <h2>请使用微信扫描二维码绘制手写签名</h2>
                <el-button type="primary" @click="getNewCode"
                  >刷新二维码</el-button
                >
                <el-button
                  type="primary"
                  @click="downloadSignatureCode(qrCodeImg)"
                  >下载二维码</el-button
                >
              </div>
              <div class="signatureArea">
                <span>预览</span>
                <div class="signatureBox">
                  <img
                    :src="signatureImg"
                    alt=""
                    v-if="signatureImg"
                    class="signatureImg"
                  />
                  <dl v-else>
                    <dt>
                      <img
                        src="@/assets/image/businessFor/signature.png"
                        alt=""
                      />
                    </dt>
                    <dd>暂无手写签名</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item-title">
          经办人基本信息<span class="hint"
            >（经办人手机号用于接收证书密码，业务验证码，证书解锁信息等）</span
          >
        </div>
        <div class="item-box">
          <el-form-item label="经办人姓名" prop="orgForm.agentName">
            <el-input
              disabled
              v-model="information.orgForm.agentName"
              placeholder="请输入个人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="经办人身份证号" prop="orgForm.agentIdentNo">
            <el-input
              disabled
              v-model="information.orgForm.agentIdentNo"
              placeholder="请输入证件号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="经办人手机号" prop="orgForm.agentMobile">
            <el-input
              disabled
              v-model="information.orgForm.agentMobile"
              placeholder="请输入个人手机号"
            ></el-input>
          </el-form-item>
        </div>

        <div class="item-title">领取方式</div>
        <el-form-item class="label-position-left" label="领取方式">
          <el-radio-group v-model="information.shipping">
            <el-radio :label="false">自取</el-radio>
            <el-radio :label="true">邮寄到付</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="information.shipping">
          <div class="item-box" v-if="information.shipping">
            <el-form-item label="收件人姓名" prop="recipientName">
              <el-input
                v-model="information.recipientName"
                placeholder="请输入收件人姓名"
                minlength="2"
                maxlength="60"
              ></el-input>
            </el-form-item>
            <el-form-item label="收件人手机号" prop="recipientMobile">
              <el-input
                v-model="information.recipientMobile"
                placeholder="请输入收件人手机号"
                maxlength="11"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item label="收件地址" prop="mailingAddress">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入收件地址"
              v-model="information.mailingAddress"
            >
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <el-button class="submitButn" @click="nextStep" type="primary"
        >下一步</el-button
      >
    </div>
    <div v-else class="confirmation">
      <div class="item-title">项目信息</div>
      <div class="item-box">
        <div>
          所属项目：<span>{{ getProjectName(information.projectId) }}</span>
        </div>
        <div>
          证书类型：<span>{{ certTemplateActive.alias }}</span>
        </div>
      </div>
      <div class="item-title">机构信息</div>
      <div class="item-box">
        <div>
          机构名称/O：<span>{{ information.orgForm.organization }}</span>
        </div>
        <div>
          社会统一信用代码：<span>{{ information.orgForm.orgIdentNo }}</span>
        </div>
        <div>
          部门/OU：<span>{{ information.orgForm.organizationalUnit }}</span>
        </div>
        <div>
          企业注册地址：<span>{{
            information.orgForm.stateOrProvince +
              '/' +
              information.orgForm.locality
          }}</span>
        </div>
      </div>
      <div class="item-title">法定代表人信息</div>
      <div class="item-box">
        <div>
          法定代表人姓名：<span>{{ information.orgForm.legalPersonName }}</span>
        </div>
        <div>
          法定代表人证件类型：<span>{{
            information.orgForm.legalPersonIdentType | IdentType
          }}</span>
        </div>
        <div>
          法定代表人证件号码：<span>{{
            information.orgForm.legalPersonIdentType === '0'
              ? information.orgForm.legalPersonIdentNo
              : information.orgForm.legalIdentNo
          }}</span>
        </div>
      </div>
      <template v-if="certTemplateActive.certType !== 2">
        <div class="item-title">
          {{ sealProject || isSealForm ? '企业/机构负责人信息' : '用户信息' }}
        </div>
        <div class="item-box">
          <div>
            姓名/CN：<span>{{ information.personForm.name }}</span>
          </div>
          <div>
            证件号码：<span>{{ information.personForm.identNo }}</span>
          </div>
          <div>
            手机号：<span>{{ information.personForm.mobile }}</span>
          </div>
          <div>
            邮箱：<span>{{ information.personForm.email || '--' }}</span>
          </div>
        </div>
      </template>
      <div class="item-title">证书信息</div>
      <div class="item-box">
        <div>
          证书年限：<span>{{ information.certDuration / 365 + '年' }}</span>
        </div>
        <div>
          证书名称/CN：<span>{{
            certTemplateActive.certType !== 2
              ? information.personForm.name
              : information.orgForm.organization
          }}</span>
        </div>
      </div>
      <div v-if="sealProject || isSealForm" class="item-title">印迹信息</div>
      <div v-if="sealProject || isSealForm" class="item-box">
        <div class="sealImgs">
          <img :src="information.patternFileBase64" alt="" />
        </div>
        <div>
          印迹尺寸：<span
            >{{ information.patternWidth }}mm x
            {{ information.patternHeight }}mm</span
          >
        </div>
      </div>
      <div class="item-title">经办人信息</div>
      <div class="item-box">
        <div>
          经办人姓名：<span>{{ information.orgForm.agentName }}</span>
        </div>
        <div>
          经办人身份证号：<span>{{ information.orgForm.agentIdentNo }}</span>
        </div>
        <div>
          经办手机号：<span>{{ information.orgForm.agentMobile }}</span>
        </div>
      </div>
      <div class="item-title">领取方式</div>
      <div class="item-box">
        <div>
          领取方式：<span>{{
            information.shipping ? '邮寄到付' : '自取'
          }}</span>
        </div>
        <div v-if="information.shipping">
          收件人姓名：<span>{{ information.recipientName }}</span>
        </div>
        <div v-if="information.shipping">
          收件人手机号：<span>{{ information.recipientMobile }}</span>
        </div>
        <div v-if="information.shipping">
          收件地址：<span>{{ information.mailingAddress }}</span>
        </div>
      </div>
      <div class="buttons">
        <el-button @click="applySubmit" type="primary">确认业务单</el-button>
        <el-button plain @click="confirmationShow = false">返回修改</el-button>
      </div>
    </div>
    <el-dialog
      custom-class="exampleBox"
      title="上传示例"
      :visible.sync="exampleShow"
      width="668px"
    >
      <div class="">
        <div>
          <div class="exampleTitle">
            请将印章盖在【空白A4纸】上，确保清晰完整，拍照或扫描彩色上传
          </div>
          <div class="exampleImg"></div>
          <div class="exampleImg1"></div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="exampleShow = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog
      custom-class="exampleBox"
      title="扣章教程"
      :visible.sync="tutorialsShow"
      width="890px"
    >
      <video controls width="850" autoplay ref="tutorials">
        <source
          src="https://yhca-pro-public-download.oss-cn-hangzhou.aliyuncs.com/%E8%B1%B8%E4%BF%A1%E4%B8%8A%E4%BC%A0%E5%8D%B0%E7%AB%A0%E6%BC%94%E7%A4%BA%E8%A7%86%E9%A2%91.mp4"
          type="video/mp4"
        />
      </video>
    </el-dialog>
  </div>
</template>

<script>
import {
  isIDcard,
  validIDcard,
  validMobile,
  validEmail,
  validCreditCode
} from '@/utils/validate'
import { IdentType } from '@/utils/typeMatch'
import citysData from '@/utils/citys'
import SealCut from '@/components/SealCut'
export default {
  props: ['projectId', 'info', 'sealProject'],
  data() {
    return {
      isIDcard: isIDcard,
      isSealForm: false,
      exampleShow: false,
      urlName: '',
      submitRes: null,
      patternFileData: {
        imgBase64: '',
        targetColor: 0,
        fixPercent: 1,
        alpha: 230,
        color: '',
        patternCustomColor: ''
      },
      patternColor: 0,
      predefineColors: ['rgb(255, 0, 0)', 'rgb(0, 0, 255)', 'rgb(0, 0, 0)'],
      targetColors: ['#EA221E', '#0083FF', '#323232'],
      patternColors: ['#ff0100', '#0000ff', '#000000'],
      impressionType: 'local',
      selectIndex: '0',
      signatureImg: '',
      qrCodeImg: '',
      qrCodeToken: '',
      patternInterval: '',
      patternStyleType: this.$DDIC.patternStyleType1,
      patternUrl: this.CONFIG.patternApi + '/pattern/official.html',
      exportSrc: this.CONFIG.patternApi + '/pattern/official.html',
      patternFileBase64_loading: false,
      patternData: {
        sealColor: '%23ff0100',
        style: 0,
        size: '40*40',
        star: true,
        branchName: '',
        sn: '',
        title: '',
        antiFake: false,
        makeOldType: 0,
        englishName: '',
        coding: '',
        englishOffset: 51.5,
        chineseOffset: 27,
        codingOffset: 58.2,
        flatSize: 44,
        fontsizea: 24,
        spacinga: 3,
        fontheighta: 160,
        fontwidtha: 100,
        fontsizeb: 20,
        spacingb: 1,
        fontheightb: 160,
        fontwidthb: 100
      },
      confirmationShow: false,
      citysData: [],
      certTemplates: [],
      certTemplateActive: {
        certType: -1
      },
      transId: '',
      projectList: [],
      information: {
        patternFileBase64: '',
        certDuration: 1095, // 证书有效期（单位：天）
        certTemplateId: '', // 证书模版id
        projectId: '', // 项目id
        shipping: true, // 是否需要邮寄
        mailingAddress: '', // 收件人地址
        skipRepeatVerify: false,
        recipientName: '',
        recipientMobile: '',
        personForm: {
          identNo: '', // 个人-证件号码
          identType: 0, // 个人-证件类型
          mobile: '',
          email: '',
          name: '' // 个人-姓名
        },
        orgForm: {
          organization: '',
          organizationalUnit: '',
          orgIdentType: 'Q',
          orgIdentNo: '',
          stateOrProvince: '',
          locality: '',
          legalPersonName: '',
          legalPersonIdentType: '0',
          legalPersonIdentNo: '',
          legalIdentNo: '',
          agentName: '',
          agentIdentType: '0',
          agentIdentNo: '',
          agentMobile: '',
          agentEmail: ''
        },
        patternHeight: '',
        patternWidth: ''
      },
      rules: {
        certTemplateId: [
          { required: true, message: '请选择需要的证书类型', trigger: 'change' }
        ],
        patternFileBase64: [
          { required: true, message: '请上传印迹', trigger: 'change' }
        ],
        certDuration: [
          { required: true, message: '请选择证书年限', trigger: 'change' }
        ],
        mailingAddress: [
          { required: true, message: '请输入您的收件地址', trigger: 'blur' },
          {
            min: 2,
            max: 40,
            message: '请输入您的收件地址（2～40个字）',
            trigger: 'change'
          }
        ],
        projectId: [
          { required: true, message: '请输入个人真实姓名', trigger: 'blur' }
        ],
        recipientName: [
          { required: true, message: '请输入收件人姓名', trigger: 'blur' }
          // {
          //   validator: validName,
          //   message: '请输入收件人真实姓名',
          //   trigger: 'blur'
          // }
        ],
        recipientMobile: [
          { required: true, message: '请输入收件人手机号', trigger: 'blur' },
          {
            message: '请输入正确的收件人手机号',
            validator: validMobile,
            trigger: 'blur'
          }
        ],
        personForm: {
          name: [
            { required: true, message: '请输入个人真实姓名', trigger: 'blur' }
            // {
            //   validator: validName,
            //   message: '请输入您的真实姓名',
            //   trigger: 'blur'
            // }
          ],
          identNo: [
            { required: true, message: ' 请输入个人证件号码', trigger: 'blur' },
            {
              validator: validIDcard,
              message: '请输入正确的18位身份证号码',
              trigger: 'blur'
            }
          ],
          mobile: [
            { required: true, message: '请输入个人手机号码', trigger: 'blur' },
            {
              message: '请输入正确的个人手机号码',
              validator: validMobile,
              trigger: 'blur'
            }
          ],
          email: [
            {
              required: false,
              validator: validEmail,
              message: '请输入正确的电子邮箱',
              trigger: 'blur'
            }
          ]
        },
        orgForm: {
          organization: [
            { required: true, message: '请输入机构名称', trigger: 'blur' }
            // ,
            // {
            //   pattern: /^[A-Za-z0-9（）\u4e00-\u9fa5]+$/,
            //   message: '请输入机构名称',
            //   trigger: 'blur'
            // }
          ],
          organizationalUnit: [
            { required: true, message: '请输入部门名称', trigger: 'blur' }
          ],
          orgIdentNo: [
            {
              required: true,
              message: '请输入统一社会信用代码',
              trigger: 'blur'
            },
            {
              validator: validCreditCode,
              message: '请输入正确的统一社会信用代码',
              trigger: 'blur'
            }
          ],
          stateOrProvince: [
            { required: true, message: '请选择企业注册省市', trigger: 'change' }
          ],
          locality: '',
          legalPersonName: [
            { required: true, message: '请输入法人姓名', trigger: 'blur' }
            // {
            //   validator: validName,
            //   message: '请输入真实法人姓名',
            //   trigger: 'blur'
            // }
          ],
          legalPersonIdentType: [
            {
              required: true,
              message: '请选择法定代表人证件类型',
              trigger: 'change'
            }
          ],
          legalPersonIdentNo: [
            { required: true, message: ' 请输入法人证件号码', trigger: 'blur' },
            {
              validator: validIDcard,
              message: '请输入正确的18位身份证号码',
              trigger: 'blur'
            }
          ],
          legalIdentNo: [
            { required: true, message: ' 请输入法人证件号码', trigger: 'blur' }
          ],
          agentName: [
            { required: true, message: '请输入经办人姓名', trigger: 'blur' }
          ],
          agentIdentType: '0',
          agentIdentNo: [
            { required: true, message: '请输入经办人身份证号', trigger: 'blur' }
          ],
          agentMobile: [
            { required: true, message: '请输入经办人手机号', trigger: 'blur' }
          ],
          agentEmail: ''
        }
      },
      provinceAndCity: [],
      patternRules: {
        branchName: [
          {
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9()（）]+$/,
            message: '请填写正确的分公司名称',
            trigger: 'blur'
          }
        ],
        sn: [
          {
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9()（）]{2,}$/,
            message: '请填写正确的下旋文',
            trigger: 'blur'
          }
        ],
        title: [
          {
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9()（）]+$/,
            message: '请填写正确的横向文',
            trigger: 'blur'
          }
        ],
        englishName: [
          {
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9()（） ]+$/,
            message: '请填写正确的英文名',
            trigger: 'blur'
          }
        ],
        coding: [
          {
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9()（）]+$/,
            message: '请填写正确的编码',
            trigger: 'blur'
          }
        ],
        size: [
          { required: true, message: '请选择印章尺寸', trigger: 'change' }
        ],
        star: [
          { required: true, message: '请选择中刊五角星', trigger: 'change' }
        ],
        antiFake: [
          { required: true, message: '请选择印章还原度', trigger: 'change' }
        ],
        makeOldType: [
          { required: true, message: '请选择做旧方案', trigger: 'change' }
        ]
      },
      tutorialsShow: false
    }
  },
  components: {
    SealCut
  },
  filters: {
    IdentType
  },
  created() {
    this.getProjectList()
    let transId = this.$route.query.transId
    if (transId) {
      this.transId = transId
      this.initialize()
    } else {
      this.information.projectId = this.projectId
    }
    // children label value
    this.clearCitysData()
    this.$api.getAccountInfo().then((res) => {
      this.information.orgForm.agentName = res.data.name
      this.information.orgForm.agentIdentNo = res.data.identNo
      this.information.orgForm.agentMobile = res.data.mobile
    })
  },
  methods: {
    getexample() {
      this.exampleShow = true
    },
    patternMatting() {
      if (
        this.patternFileData.imgBase64 &&
        this.patternFileData.imgBase64 != ''
      ) {
        this.patternFileBase64_loading = true
        this.$api
          .patternMatting(this.patternFileData)
          .then((res) => {
            this.information.patternFileBase64 = res.data.sealImage
            this.patternFileBase64_loading = false
          })
          .catch(() => {
            this.patternFileBase64_loading = false
          })
      }
    },
    applyFileUpload(data) {
      let file = data.file
      const fileMaxSize = 1024 * 1024 * 20
      if (file.size > fileMaxSize) {
        this.$message({
          message: '文件大小不可超过20M',
          type: 'error'
        })
        return false
      }
      let formdata = new FormData()
      formdata.append('file', file)
      this.$api
        .applyFileUpload(formdata)
        .then((res) => {
          this.information.applyFilePath = res.data.filePath
          this.applyFile = file
        })
        .catch((error) => {
          console.log(error)
        })
    },
    sealCutSave(imgBase64, data) {
      this.patternFileData.imgBase64 = imgBase64
      this.information = { ...this.information, ...data }
      this.patternMatting()
    },
    // 获取短信验证码
    getSmsVerCode() {
      this.$api
        .getSmsVerCode(this.verify_data)
        .then((res) => {
          this.verify_code_time = 60
          this.interval = setInterval(() => {
            if (this.verify_code_time <= 0) {
              clearInterval(this.interval)
              return
            }
            this.verify_code_time--
          }, 1000)
          this.information.verCodeToken = res.data.verCodeToken
        })
        .catch(() => {})
    },
    // 腾讯滑动验证吗
    getTencentCaptcha(mobile) {
      if (!mobile || mobile == '') {
        this.$message.error('请输入手机号')
        return
      }
      this.verify_data.mobile = mobile
      let appid = this.CONFIG.VUE_APP_TENCENT_APPID // 腾讯云控制台中对应这个项目的 appid
      let _this = this
      // eslint-disable-next-line no-undef
      let captcha = new TencentCaptcha(appid, function(res) {
        if (res.ret === 0) {
          _this.verify_data.randStr = res.randstr
          _this.verify_data.ticket = res.ticket
          _this.getSmsVerCode()
        }
      })
      // 滑块显示
      captcha.show()
    },
    pdfPreview(file) {
      let url = URL.createObjectURL(file)
      this.pdfPreviewUrl = url
      this.dialog_pdf = true
    },
    windowClose() {
      this.$confirm('确定取消申请并关闭当前页面？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        window.close()
      })
      //
    },
    isContact(rule, value, callback) {
      let phoneRule = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (value) {
        if (!phoneRule.test(value)) {
          return callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      } else {
        return callback(new Error('请输入正确的手机号'))
      }
    },
    formValidate(bc) {
      let promises = []
      for (let i = 0; i < 5; i++) {
        let promise = new Promise((resolve, reject) => {
          if (this.$refs['form' + i]) {
            this.$refs['form' + i].validate((valid, res) => {
              if (valid) {
                resolve()
              } else {
                reject(res)
              }
            })
          } else {
            resolve()
          }
        })
        promises.push(promise)
      }
      Promise.all(promises)
        .then(() => {
          bc()
        })
        .catch((err) => {
          console.log(err)
          this.$message.error('请填写完整信息，再次进行申请')
        })
    },
    setPatternColor() {
      let color =
        '%23' +
        this.patternColors[this.patternFileData.targetColor - 1].split('#')[1]
      this.patternData.sealColor = color
      this.createPattern()
    },
    setPatternUrl(item) {
      switch (item.name) {
        case '圆章':
          this.patternStyleType = this.$DDIC.patternStyleType1
          this.patternData.size = '42*42'
          this.information.patternHeight = 42
          this.information.patternWidth = 42
          this.patternData.fontsizea = '24'
          this.exportSrc = this.patternUrl =
            this.CONFIG.patternApi + '/pattern/official.html'
          this.information.orgForm.organization !== ''
            ? (this.exportSrc +=
                '?chara=' + this.information.orgForm.organization)
            : this.exportSrc
          break
        case '椭圆章':
          this.patternStyleType = this.$DDIC.patternStyleType2
          this.patternData.size = '40*30'
          this.information.patternHeight = 30
          this.information.patternWidth = 40
          this.patternData.fontsizea = '20'
          this.exportSrc = this.patternUrl =
            this.CONFIG.patternApi + '/pattern/ellipse.html'
          this.information.orgForm.organization !== ''
            ? (this.exportSrc +=
                '?chara=' + this.information.orgForm.organization)
            : this.exportSrc
          break
        case '中英章':
          this.patternStyleType = this.$DDIC.patternStyleType2
          this.patternData.size = '40*30'
          this.information.patternHeight = 30
          this.information.patternWidth = 40
          this.patternUrl = this.CONFIG.patternApi + '/pattern/chungYing.html'
          this.exportSrc =
            this.CONFIG.patternApi +
            '/pattern/chungYing.html?chara=' +
            this.patternData.englishName
          this.information.orgForm.organization !== ''
            ? (this.exportSrc +=
                '&charb=' + this.information.orgForm.organization)
            : this.exportSrc
          break
        case '正方形章':
          this.patternData.size = '20*20'
          this.patternStyleType = this.$DDIC.patternStyleType3
          this.patternData.flatSize = 44
          this.information.patternHeight = 20
          this.information.patternWidth = 20
          this.exportSrc = this.patternUrl =
            this.CONFIG.patternApi + '/pattern/square.html'
          this.information.personForm.name !== ''
            ? (this.exportSrc +=
                '?chara=' + this.information.personForm.name + '印')
            : this.exportSrc
          break
        case '长方形章':
          this.patternData.flatSize = 35
          this.information.patternHeight = 10
          this.exportSrc = this.patternUrl =
            this.CONFIG.patternApi + '/pattern/flat.html'
          this.information.personForm.name !== ''
            ? (this.exportSrc += '?chara=' + this.information.personForm.name)
            : this.exportSrc
          break
        default:
          break
      }
      this.resetData()
    },
    resetData() {
      this.patternData.star = true
      this.patternData.antiFake = false
      this.patternData.makeOldType = 0
      this.patternData.title = ''
      this.patternData.sn = ''
      this.patternData.englishName = ''
      this.patternData.englishOffset = 51.5
      this.patternData.chineseOffset = 27
      this.patternData.coding = ''
      this.patternData.codingOffset = 58.2
      if (this.impressionType === 'local') {
        this.patternFileData.targetColor = 0
      } else {
        this.patternFileData.targetColor = 1
      }
      this.patternData.branchName = ''
    },
    createPattern() {
      const ring = this.patternData.antiFake
        ? '&ringframe=solid&ring=lineobl'
        : ''
      const star = this.patternData.star ? 'star' : 'none'
      let parameters = this.patternUrl
      const sealType = this.patternUrl
        .split('.html')[0]
        .split('/')
        .pop()
      if (sealType === 'chungYing') {
        parameters +=
          '?chara=' +
          this.patternData.englishName +
          '&startoffseta=' +
          this.patternData.englishOffset +
          '&&startoffsetb=' +
          this.patternData.chineseOffset +
          '&charc=' +
          this.patternData.coding +
          '&startoffsetc=' +
          this.patternData.codingOffset +
          '&backalpha=on&forealpha=90&forecolor=' +
          this.patternData.sealColor +
          '&sign=' +
          star
      } else if (sealType === 'square' || sealType === 'flat') {
        parameters +=
          '?&forecolor=' +
          this.patternData.sealColor +
          '&backalpha=on&forealpha=90&fontsizea=' +
          this.patternData.flatSize
      } else {
        parameters +=
          '?&charb=' +
          this.patternData.title +
          '&charc=' +
          this.patternData.sn +
          '&forecolor=' +
          this.patternData.sealColor +
          '&backalpha=on&forealpha=90&sign=' +
          star
      }

      if (
        sealType === 'chungYing' &&
        this.information.orgForm.organization !== ''
      ) {
        // 中英章避免机构名称为空
        parameters += '&charb=' + this.information.orgForm.organization
      }
      if (
        this.information.orgForm.organization !== '' &&
        (sealType === 'official' || sealType === 'ellipse')
      ) {
        // 圆章、椭圆章，避免机构名称为空
        parameters +=
          '&chara=' +
          this.information.orgForm.organization +
          this.patternData.branchName
      }
      if (sealType === 'official' || sealType === 'ellipse') {
        parameters +=
          '&fontsizea=' +
          this.patternData.fontsizea +
          '&charspacinga=' +
          this.patternData.spacinga +
          '&fontheighta=' +
          this.patternData.fontheighta +
          '&fontwidtha=' +
          this.patternData.fontwidtha +
          '&fontsizeb=' +
          this.patternData.fontsizeb +
          '&charspacingb=' +
          this.patternData.spacingb +
          '&fontheightb=' +
          this.patternData.fontheightb +
          '&fontwidthb=' +
          this.patternData.fontwidthb
      }
      if (this.information.personForm.name && sealType === 'square') {
        // 长形章及方形章，避免法人姓名为空
        parameters += '&chara=' + this.information.personForm.name + '印'
      }
      if (this.information.personForm.name && sealType === 'flat') {
        // 长形章，避免法人姓名为空
        parameters += '&chara=' + this.information.personForm.name
      }
      if (this.information.personForm.name !== '' && sealType === 'flat') {
        if (this.information.personForm.name.length === 2) {
          this.information.patternWidth = 16
        } else if (this.information.personForm.name.length === 3) {
          this.information.patternWidth = 23
        } else if (this.information.personForm.name.length === 4) {
          this.information.patternWidth = 30
        }
      }

      if (this.patternData.makeOldType > 0) {
        // 做旧
        parameters += '&effect=' + this.patternData.makeOldType
      }
      if (this.patternData.antiFake && sealType === 'official' && ring !== '') {
        // 防伪环
        parameters += ring
      }

      if (sealType === 'ellipse') {
        // 椭圆章下旋文位置为底部
        parameters += '&positionc=bottom'
      }
      this.exportSrc = parameters
    },
    setSealUrl() {
      this.selectIndex = '0'
      this.resetData()
      if (this.certTemplateActive.certType === 2) {
        this.patternStyleType = this.$DDIC.patternStyleType1
        this.patternData.size = '42*42'
        this.getOfficialPattern()
      } else {
        this.patternStyleType = this.$DDIC.patternStyleType3
        this.patternData.size = '20*20'
        this.getPersonPattern()
      }
    },
    getSealImg(type) {
      this.impressionType = type
      if (this.impressionType === 'local') {
        this.patternFileData.targetColor = 0
      } else {
        this.patternFileData.targetColor = 1
      }
      this.information.patternFileBase64 = ''
      const that = this
      if (this.certTemplateActive.certType === 2) {
        this.patternStyleType = this.$DDIC.patternStyleType1
        this.patternData.size = '42*42'
        this.getOfficialPattern()
      } else {
        this.patternStyleType = this.$DDIC.patternStyleType3
        this.patternData.size = '20*20'
        this.patternUrl = this.CONFIG.patternApi + '/pattern/square.html'
        this.getPersonPattern()
      }
      window.addEventListener(
        'message',
        function(e) {
          var reader = new window.FileReader()
          reader.readAsDataURL(e.data)
          reader.onloadend = function() {
            that.information.patternFileBase64 = reader.result
          }
        },
        true
      )

      if (this.impressionType === 'handwritten') {
        this.getNewCode()
      }
    },
    getOfficialPattern() {
      this.patternUrl = this.CONFIG.patternApi + '/pattern/official.html'
      this.information.patternHeight = 42
      this.information.patternWidth = 42
      if (this.information.orgForm.organization !== '') {
        this.exportSrc =
          this.CONFIG.patternApi +
          '/pattern/official.html?chara=' +
          this.information.orgForm.organization
      } else {
        this.exportSrc = this.CONFIG.patternApi + '/pattern/official.html'
      }
    },
    getPersonPattern() {
      this.patternUrl = this.CONFIG.patternApi + '/pattern/square.html'
      this.information.patternHeight = 20
      this.information.patternWidth = 20
      if (this.information.personForm.name !== '') {
        this.exportSrc =
          this.CONFIG.patternApi +
          '/pattern/square.html?chara=' +
          this.information.personForm.name +
          '印'
      } else {
        this.exportSrc = this.CONFIG.patternApi + '/pattern/square.html'
      }
    },
    getNewCode() {
      clearInterval(this.patternInterval)
      this.patternInterval = ''
      const url = window.location.href.split('/#')[0]
      const data = { domain: url }
      this.$api
        .getQRCode(data)
        .then((res) => {
          this.qrCodeImg = 'data:image/png;base64,' + res.data.qrCodeBase64
          this.qrCodeToken = res.data.token
          this.patternInterval = setInterval(() => {
            this.getSignature()
          }, 2000)
        })
        .catch(() => {})
    },
    getSignature() {
      const data = { token: this.qrCodeToken }
      this.$api
        .getHandWritten(data)
        .then((res) => {
          if (res.code === '0') {
            this.signatureImg = res.data.imageBase64
            this.information.patternFileBase64 = res.data.imageBase64
            this.information.patternWidth = res.data.width
            this.information.patternHeight = res.data.height
          } else if (res.code === '201') {
            this.$confirm(res.msg, '提示', {
              confirmButtonText: '确定',
              showCancelButton: false,
              type: 'warning'
            }).then(() => {})
          } else if (res.code === '500') {
            this.$confirm(res.msg, '提示', {
              confirmButtonText: '确定',
              showCancelButton: false,
              type: 'warning'
            }).then(() => {})
            clearInterval(this.patternInterval)
            this.patternInterval = ''
          } else {
            clearInterval(this.patternInterval)
            this.patternInterval = ''
          }
        })
        .catch(() => {})
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(',')
      // 注意base64的最后面中括号和引号是不转译的
      var _arr = arr[1].substring(0, arr[1].length - 2)
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(_arr)
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], {
        type: mime
      })
    },
    downloadSignatureCode(blob) {
      const signatureCode = this.dataURLtoBlob(blob)
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(signatureCode, '手写签名二维码.png')
      } else {
        // 这里就按照chrome等新版浏览器来处理
        let aLink = document.createElement('a')
        let evt = document.createEvent('HTMLEvents')
        evt.initEvent('click', true, true) // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        aLink.href = URL.createObjectURL(signatureCode)
        aLink.download = '手写签名二维码.png'
        aLink.click()
      }
    },
    setPatternSize() {
      this.information.patternHeight = this.patternData.size.split('*')[1]
      this.information.patternWidth = this.patternData.size.split('*')[0]
    },
    getBase64ByUrl(src, callback, outputFormat) {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', src, true)
      xhr.responseType = 'arraybuffer'
      xhr.onload = function() {
        if (xhr.status == 200) {
          var uInt8Array = new Uint8Array(xhr.response)
          var i = uInt8Array.length
          var binaryString = new Array(i)
          while (i--) {
            binaryString[i] = String.fromCharCode(uInt8Array[i])
          }
          var data = binaryString.join('')
          var base64 = window.btoa(data)
          var dataUrl =
            'data:' + (outputFormat || 'image/png') + ';base64,' + base64
          callback.call(this, dataUrl)
        }
      }
      xhr.send()
    },
    getCertTemplates() {
      this.$api
        .getCertTemplates({ projectId: this.information.projectId })
        .then((res) => {
          this.certTemplates = res.data
        })
    },
    nextStep() {
      this.$refs.information.validate((valid) => {
        if (valid) {
          if (this.sealProject && !this.information.patternFileBase64) {
            this.$alert('请添加印迹信息后再进行提交！', '温馨提示', {
              confirmButtonText: '确定'
            })
            return
          }
          this.confirmationShow = true
          // } else {
          //   this.$alert(
          //     '请检查是否将所有信息填写正确完整，或存在未选中的选项！',
          //     '温馨提示',
          //     {
          //       confirmButtonText: '确定'
          //     }
          //   )
          // }
        } else {
          this.$nextTick(() => {
            const isError = document.getElementsByClassName('is-error')
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth'
            })
          })
        }
      })
    },
    applySubmit() {
      this.$tencentCaptcha((val) => {
        let information = { ...val, ...this.information }
        let orgForm = JSON.parse(JSON.stringify(information.orgForm))
        delete orgForm.legalIdentNo
        if (this.certTemplateActive.certType === 2) {
          delete information.personForm
        }
        if (this.sealProject || this.isSealForm) {
          information['sealForm'] = {
            patternFileBase64: this.information.patternFileBase64,
            patternHeight: this.information.patternHeight,
            patternWidth: this.information.patternWidth
          }
        }
        if (this.information.orgForm.legalPersonIdentType === 'Z') {
          orgForm.legalPersonIdentNo = this.information.orgForm.legalIdentNo
          information.orgForm = orgForm
        }
        this.$api.applySubmit(information).then((res) => {
          if (res.code === '0') {
            this.$parent.transId = res.data.id
            this.$router.push({
              query: { transId: res.data.id }
            })
          } else if (res.code == '201') {
            this.$confirm(
              '当前证件号存在未完成的证书新办业务，您可以选择继续办理业务',
              '温馨提示',
              {
                distinguishCancelAndClose: true,
                confirmButtonText: '查看业务单',
                cancelButtonText: '仍要提交',
                type: 'warning'
              }
            )
              .then(() => {
                this.$router.push({ name: 'serviceCenter', query: { type: 1 } })
              })
              .catch((action) => {
                if (action === 'cancel') {
                  this.information.skipRepeatVerify = true
                  this.applySubmit()
                }
              })
          } else {
            this.$alert(res.msg, '提示', {
              confirmButtonText: '确定',
              type: 'warning',
              callback: () => {}
            })
          }
        })
      })
    },
    provinceAndCityCg() {
      this.information.orgForm.stateOrProvince = this.provinceAndCity[0]
      this.information.orgForm.locality = this.provinceAndCity[1]
    },
    clearCitysData() {
      for (let province of citysData) {
        for (let city of province.children) {
          delete city.children
        }
      }
      this.citysData = citysData
    },
    getProjectList() {
      this.$api.getProjectList().then((res) => {
        this.projectList = res.data
      })
    },
    initialize() {
      this.$api
        .getTransactionOrDetail({ transactionId: this.transId })
        .then((res) => {
          let form = res.data && res.data.applyFormEntity
          let information = {
            transId: this.transId,
            certDuration: form.certDuration, // 证书有效期（单位：天）
            certTemplateId: form.certTemplateId, // 证书模版id
            projectId: form.projectId, // 项目id
            shipping: form.shipping, // 是否需要邮寄
            mailingAddress: form.mailingAddress, // 收件人地址
            recipientName: form.recipientName, // 收件人姓名
            recipientMobile: form.recipientMobile, // 收件人手机号
            skipRepeatVerify: false
          }

          if (form.personInfoEntity) {
            information['personForm'] = {
              identNo: form.personInfoEntity.identNo, // 个人-证件号码
              identType: form.personInfoEntity.identType, // 个人-证件类型
              mobile: form.personInfoEntity.mobile,
              email: form.personInfoEntity.email,
              name: form.personInfoEntity.name // 个人-姓名
            }
          }
          if (form.orgInfoEntity) {
            this.provinceAndCity = [
              form.orgInfoEntity.stateOrProvince,
              form.orgInfoEntity.locality
            ]
            information['orgForm'] = {
              organization: form.orgInfoEntity.organization,
              organizationalUnit: form.orgInfoEntity.organizationalUnit,
              orgIdentType: form.orgInfoEntity.orgIdentType,
              orgIdentNo: form.orgInfoEntity.orgIdentNo,
              stateOrProvince: form.orgInfoEntity.stateOrProvince,
              locality: form.orgInfoEntity.locality,
              legalPersonName: form.orgInfoEntity.legalPersonName,
              legalPersonIdentType: form.orgInfoEntity.legalPersonIdentType,
              legalPersonIdentNo:
                form.orgInfoEntity.legalPersonIdentType === '0'
                  ? form.orgInfoEntity.legalPersonIdentNo
                  : form.orgInfoEntity.legalIdentNo,
              agentName: form.orgInfoEntity.agentName,
              agentIdentType: form.orgInfoEntity.agentIdentType,
              agentIdentNo: form.orgInfoEntity.agentIdentNo,
              agentMobile: form.orgInfoEntity.agentMobile,
              agentEmail: form.orgInfoEntity.agentEmail
            }
          }
          if (form.isSealForm) {
            this.isSealForm = true
            this.getBase64ByUrl(
              form.sealInfoEntitys[0].patternFilePath,
              (data) => {
                information.patternFileBase64 = data
                information.patternHeight =
                  form.sealInfoEntitys[0].patternHeight
                information.patternWidth = form.sealInfoEntitys[0].patternWidth
                this.information = { ...this.information, ...information }
                this.getCertTemplates()
              }
            )
          } else {
            this.information = { ...this.information, ...information }
            this.getCertTemplates()
          }
        })
    },
    getProjectName(id) {
      let project = this.projectList.find((item) => {
        return item.id === id
      })
      return project ? project.name : ''
    },
    changeColor(val) {
      if (!val) {
        this.patternFileData.color = ''
        this.patternFileData.targetColor = 0
      } else {
        this.patternFileData.targetColor = -1
        this.patternFileData.color = val
          .replace(/rgba?\(/, '')
          .replace(/\)/, '')
          .replace(/[\s+]/g, '')
      }
      this.reductionInput()
    },
    reductionInput: function() {
      // 更改还原度
      if (this.patternFileData.imgBase64) {
        this.patternMatting()
      }
    },
    ImgColorSelect: function(val) {
      // 更改颜色
      this.patternFileData.targetColor = val
      if (
        this.patternFileData.targetColor === -1 &&
        !this.patternFileData.color
      )
        return false
      this.reductionInput()
    },
    viewTutorials() {
      this.tutorialsShow = true
      this.$refs.tutorials.currentTime = 0
    },
    setCustomColor() {
      this.patternFileData.patternCustomColor =
        this.patternFileData.color === ''
          ? 'rgb(255, 0, 0)'
          : this.patternFileData.color
    },
    currentColor(val) {
      this.patternFileData.patternCustomColor = val
    }
  },
  watch: {
    'information.projectId'() {
      this.getCertTemplates()
    },
    certTemplates() {
      this.certTemplates.forEach((e) => {
        if (e.id === this.information.certTemplateId) {
          e.certType = Number(e.certType)
          this.certTemplateActive = e
        }
      })
      this.$refs['information'].clearValidate()
    },
    'information.certTemplateId'(val) {
      this.certTemplates.forEach((e) => {
        if (e.id === val) {
          e.certType = Number(e.certType)
          this.certTemplateActive = e
        }
      })
      this.$refs['information'].clearValidate()
    },
    'information.shipping': function(val) {
      if (val) {
        this.$set(this.information, 'recipientName', '')
        this.$set(this.information, 'recipientMobile', '')
        this.$set(this.information, 'mailingAddress', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.information-form {
  text-align: center;
  padding: 16px 24px;
  .card {
    .form {
      padding: 48px 2px 2px;
      position: relative;
      .select-targetColors {
        display: flex;
        & > div {
          padding: 2px;
          border-radius: 2px;
          background: #f8f9fc;
          border: 1px solid transparent;
          margin-right: 20px;
          &.active {
            border: 1px solid #dee2e8;
          }
          span {
            width: 52px;
            height: 34px;
            display: block;
            border-radius: 2px;
            text-align: center;
            font-size: 16px;
            color: #ffffff;
            line-height: 34px;
          }
        }
      }
      .el-input {
        width: 404px;
      }
      .hint {
        font-size: 12px;
        color: #313d62;
        line-height: 16px;
        margin-top: 6px;
      }
      .el-select {
        width: 404px;
      }
      .branchName {
        margin-top: 16px;
        margin-left: 100px;
      }
      .sealArea {
        position: absolute;
        top: 82px;
        left: 617px;
        p {
          margin-bottom: 8px;
        }
        .select-targetColors {
          justify-content: center;
        }
        .el-form-item {
          width: 257px;
        }
      }
      .sealImg {
        width: 234px;
        height: 234px;
        border: 1px dashed #c1c7d0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 18px;
        img {
          max-height: 320px;
        }
        &.loadImg {
          background: none;
        }
      }
    }
    .uniqueform {
      padding-bottom: 32px;
    }
    .el-form-item__content .slider {
      width: 348px;
    }
    .patterngenerate {
      border-radius: 4px;
      border: 1px solid #dee2e8;
      padding: 24px 32px 0 32px;
      h2 {
        font-size: 16px;
        font-weight: bold;
        color: #313d62;
        line-height: 21px;
        margin: 13px 0;
      }
      dl {
        display: inline-block;
        width: 102px;
        margin-right: 24px;
      }
      dt {
        width: 102px;
        height: 102px;
        background: #ffffff;
        border-radius: 1px;
        border: 1px solid #dee2e8;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .active {
        border-color: #3a6afe;
      }
      img {
        width: 80px;
      }
      dd {
        margin: 0;
        margin-top: 12px;
        text-align: center;
        font-size: 14px;
        color: #a7afc2;
        line-height: 19px;
      }
    }
    .handwrittenArea {
      padding: 24px 32px 32px 32px;
    }
    ::v-deep.templateStyle {
      .el-input.is-disabled {
        margin-right: 8px;
      }
      .el-form-item__label {
        float: left;
        width: 100px;
        margin-bottom: 0;
        line-height: 32px;
      }
      .el-input.is-disabled .el-input__inner {
        color: #313d62;
      }
      .el-switch.is-checked .el-switch__core {
        border-color: #3a6afe;
        background-color: #3a6afe;
      }
    }
    ::v-deep .fontSettings {
      position: absolute;
      left: 617px;
      top: 452px;
      width: 560px;
      h3 {
        width: 100%;
        font-size: 14px;
        float: left;
        margin-bottom: 8px;
      }
      .el-form-item {
        width: 140px;
        float: left;
      }
      .el-select {
        width: 80px;
      }
      .el-form-item__label {
        float: left;
        width: 50px;
        margin-bottom: 0;
        line-height: 40px;
      }
      .el-input {
        width: 80px;
      }
    }
    .handwrittenCode {
      border-radius: 4px;
      border: 1px solid #dee2e8;
      padding: 32px;
      overflow: hidden;
      .qrCode {
        width: 164px;
        height: 164px;
        border-radius: 1px;
        border: 1px solid #dee2e8;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 24px;
        img {
          width: 128px;
          height: 128px;
        }
      }
      .codeTips {
        float: left;
        h2 {
          font-size: 14px;
          color: #313d62;
          line-height: 19px;
          font-weight: normal;
          margin-top: 24px;
          margin-bottom: 42px;
        }
        .el-button--primary {
          width: 102px;
          height: 34px;
        }
        .el-button {
          padding: 0;
        }
      }
      .signatureArea {
        float: right;
        display: flex;
      }
      .signatureBox {
        flex-shrink: 0;
        width: 408px;
        height: 164px;
        border-radius: 1px;
        border: 1px solid #dee2e8;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 16px;
        dt {
          text-align: center;
          margin-bottom: 16px;
        }
        img {
          width: 57px;
        }
        dd {
          margin: 0;
          font-size: 14px;
          color: #a7afc2;
          line-height: 19px;
        }
        .signatureImg {
          width: auto;
          max-width: 408px;
          max-height: 164px;
        }
      }
      .patterngenerate {
        border-radius: 4px;
        border: 1px solid #dee2e8;
        padding: 24px 32px 0 32px;
        h2 {
          font-size: 16px;
          font-weight: bold;
          color: #313d62;
          line-height: 21px;
        }
        dl {
          display: inline-block;
          width: 102px;
          margin-right: 24px;
        }
        dt {
          width: 102px;
          height: 102px;
          background: #ffffff;
          border-radius: 1px;
          border: 1px solid #dee2e8;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .active {
          border-color: #3a6afe;
        }
        img {
          width: 80px;
        }
        dd {
          margin: 0;
          margin-top: 12px;
          text-align: center;
          font-size: 14px;
          color: #a7afc2;
          line-height: 19px;
        }
      }
    }
  }
  ::v-deep.el-form {
    text-align: left;
    .el-radio {
      margin: 10px 20px 10px 0px;
    }
    .customerName {
      font-size: 14px;
      color: #6c737b;
    }

    .item-box {
      display: flex;
      flex-wrap: wrap;
      .el-form-item:nth-child(3n) {
        margin-right: 0;
      }
      .el-form-item {
        width: 368px;
        margin-right: 73px;
      }
    }
    .el-form-item {
      margin-bottom: 32px;
      width: 609px;
    }
    .el-form-item__label {
      line-height: 19px;
      margin-bottom: 14px;
      font-size: 14px;
      padding: 0;
      color: #303b50;
    }
    .label-position-left {
      width: auto;
      .el-form-item__label {
        float: left;
        width: 94px;
        line-height: 32px;
        margin-bottom: 0;
      }
      .el-form-item__content {
        margin-left: 94px;
      }
    }
  }
  .submitButn {
    width: 182px;
    margin: 24px auto;
  }
  .example {
    position: absolute;
    left: 110px;
    top: 8px;
    color: #1c67f3;
    font-size: 12px;
    cursor: pointer;
    font-weight: normal;
    span {
      color: #1c67f3;
      font-size: 14px;
    }
  }
}

.confirmation {
  padding: 16px 24px;
  .item-box {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 50%;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #6c737b;
      line-height: 19px;
      margin-bottom: 32px;
      display: flex;
      white-space: nowrap;
      span {
        font-size: 14px;
        font-weight: bold;
        color: #303b50;
        white-space: normal;
      }
    }
  }
  .buttons {
    text-align: center;
    margin: 24px auto;
    .el-button {
      width: 182px;
    }
  }
  .sealImgs {
    width: 100% !important;
    margin-bottom: 10px;
    display: block;
    img {
      max-height: 120px;
      max-width: 120px;
    }
  }
}
</style>
<style lang="scss">
.exampleBox {
  border-bottom: 1px solid #e9e9e9;
  .el-dialog__header {
    border-bottom: 1px solid #e9e9e9;
    padding: 17px 20px 19px;
    font-size: 14px;
    line-height: 22px;
  }
  .exampleTitle {
    font-size: 14px;
    line-height: 19px;
    padding: 0px 0 11px;
    text-align: center;
  }
  .exampleImg {
    width: 140px;
    height: 182px;
    margin: auto;
    background: url('~@/assets/icons/service/example-1.png') no-repeat;
    background-size: 140px 182px;
  }
  .exampleImg1 {
    width: 628px;
    height: 200px;
    margin: auto;
    background: url('~@/assets/icons/service/example-2.png') no-repeat;
    background-size: 628px 200px;
  }
}
.impressionType {
  h5 {
    color: #faad14;
    font-size: 12px;
    font-weight: normal;
    padding-left: 18px;
    margin-left: 6px;
    display: inline;
    background: url('~@/assets/image/serviceHall/yichang.png') no-repeat;
    background-size: 14px;
  }
  :v-deep .el-button + .el-button {
    margin-left: 16px;
  }
  :v-deep .el-button.el-button--primary {
    color: #1c67f3;
    background: #f1f5ff;
    border-radius: 2px;
    border: 1px solid #3a6afe;
    span {
      color: #1c67f3;
    }
  }
  .el-button.el-button--default {
    border: 1px solid #dee0e9;
    border-radius: 2px;
  }
}

.colorBox {
  padding-top: 20px;
  display: flex;
  .colorBlockBox {
    position: relative;
    cursor: pointer;
    display: inline-block;
    border: 1px solid #dee2e8;
    width: 80px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    background-color: '';
    margin-right: 24px;
    border-radius: 2px;
    .el-color-picker__color-inner {
      z-index: 9;
    }
    &.select {
      padding: 0;
      &::before {
        position: absolute;
        top: -3px;
        left: -3px;
        content: '';
        display: block;
        border: 1px solid #1c67f3;
        background: url('~@/assets/icons/service/colorBlockSelect.png')
          no-repeat right bottom;
        border-radius: 1px;
        width: 84px;
        height: 56px;
        pointer-events: none;
      }
    }
    &.colorBlockCustom {
      position: relative;
      :v-deep .el-color-picker__trigger {
        border: none;
      }
      &.select {
        padding: 0;
        .el-color-picker__color-inner {
          &::before {
            position: absolute;
            top: -3px;
            left: -3px;
            content: '';
            display: block;
            border: 1px solid #1c67f3;
            background: url('~@/assets/icons/service/colorBlockSelect.png')
              no-repeat right bottom;
            border-radius: 1px;
            width: 84px;
            height: 56px;
            pointer-events: none;
          }
        }
      }
      &:nth-of-type(4) {
        border: 1px solid #dee2e8;
        background-color: #fff;
      }
      .custom {
        position: absolute;
        top: 8px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        text-align: center;
        > span {
          display: block;
          color: #333;
        }
        .title {
          font-size: 14px;
          line-height: 18px;
          padding-top: 3px;
        }
      }
    }
  }
}
.colorBox .el-color-picker,
.colorBox .el-color-picker__trigger {
  width: 100%;
  height: 100%;
  border: 0 none;
  padding: 0;
}
.el-color-picker__color {
  border: 0 none;
  background: transparent;
}
.el-color-picker__empty {
  display: none;
}
.tutorialBox {
  position: absolute;
  top: 24px;
  left: 0;
  p {
    display: inline-block;
    color: #1c67f3;
    font-size: 12px;
    cursor: pointer;
    font-weight: normal;
    margin-right: 16px;
  }
  span {
    font-size: 14px;
  }
  h5 {
    color: #faad14;
    font-size: 12px;
    font-weight: normal;
    margin-left: 6px;
  }
}
.tutorials {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  > div {
    width: 850px;
    margin: 10% auto 0;
    text-align: center;
    position: relative;
  }
  i {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 99;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
